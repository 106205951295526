import defaultImgUrl from "assets/images/default-img.svg";
import { Artist } from "../types";

const createDefaultArtist = (id: string): Artist => ({
  // Base Fields
  id,

  // Standard Fields
  name: "",
  links: [],
  highlights: [],

  // Image
  heroImage: {
    url: defaultImgUrl,
    contentType: "image/svg",
    importDate: new Date().toISOString(),
  },

  // Tag Like Objects
  founderAttributes: [],
  missionOrCause: [],
  badges: [],
  technicalTags: [],

  // Affiliations
  affiliations: [],

  // HUG Official Curated Lists
  projectLists: [],

  // Reviews, Reactions & Rankings
  hugScore: 0,
  reviewCount: 0,
  counterMeh: 0,
  counterMustHug: 0,
  counterNah: 0,
  counterYay: 0,
  counterChefsKiss: 0,
  counterMindBlowing: 0,
  counterNeedItNow: 0,
  counterObsessed: 0,
  counterOutOfThisWorld: 0,
  counterTakeMyMoney: 0,
  counterThoughtProvoking: 0,

  // Calculated in Front End
  counterTotalReactions: 0,

  // Review Tag Rollups
  roadmapTags: [],
  artTags: [],
  founderTeamTags: [],
  communityTags: [],

  // Maintenance Fields
  createdAt: new Date().toISOString(),
  artistCollections: [],
  medium: [],
  featuredGalleryItems: [],
});

export { createDefaultArtist };
